import MainContainer from 'components/MainContainer';
import { SnackbarProvider } from 'notistack';
import { useEffect } from 'react';
import { ReactGA4Install } from 'utilities/reactGA4Event';
import * as Sentry from '@sentry/react';

const isSentryEnabled = process.env.REACT_APP_ENABLE_SENTRY === 'true';

if (isSentryEnabled) {
  Sentry.init({
    dsn: 'https://8ff8db5c2ecf0b359422ca294e36f2c8@o445902.ingest.us.sentry.io/4507499251302400',
    integrations: [Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.01,
    // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0,
    // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    enabled: process.env.REACT_APP_ENABLE_SENTRY === 'true'
  });
}

const App = () => {
  useEffect(() => {
    try {
      ReactGA4Install();
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <SnackbarProvider
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      dense
      maxSnack={3}
      style={{ fontFamily: 'Gill Sans, sans-serif' }}
    >
      <MainContainer />
    </SnackbarProvider>
  );
};

export default App;
