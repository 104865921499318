import React from 'react';
import styled from 'styled-components';

interface BranchPlaceholderPageSectionProps {
  sectionName: string;
}
const BranchPlaceholderPageSection: React.FC<BranchPlaceholderPageSectionProps> =
  ({ sectionName }) => {
    return (
      <OfficeInfoContainer>
        <SmallTitle>{sectionName}</SmallTitle>
      </OfficeInfoContainer>
    );
  };

export default BranchPlaceholderPageSection;

const OfficeInfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 40px;
  margin-bottom: 250px;
  margin-top: 250px;
  background-color: #ffffff;
`;

/* Text */

const SmallTitle = styled.h3`
  font-family: Poppins;
  font-weight: 200;
  font-size: 32px;
  line-height: 135%;
  width: 90%;
  margin: 0px;
  margin-bottom: 25px;
  @media screen and (max-width: 767px) {
    font-size: 25px;
    line-height: 125%;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    font-size: 26px;
    line-height: 125%;
  }
`;
