import { Box } from '@mui/material';
import {
  BranchInfoText,
  BranchLandingPhotoContainer,
  Container,
  Divider,
  ExpertisePill,
  OurDifferenceDescription,
  OurDifferenceDescriptionContainer,
  OurDifferenceGreenBackground,
  SectionHeader,
  SectionHeaderLarge,
  SectionHeaderTextSmall,
  Subtext,
  Title
} from 'components/General/StyledComponents';
import BranchAdvisorCardNoTeamPhoto from 'components/General/branch/BranchAdvisorCardNoTeamPhoto';
import BranchConsultantCardNoTeamPhoto from 'components/General/branch/BranchConsultantCardNoTeamPhoto';
import { provinceToFull } from 'constants/provinceToFull';
import { Branch, TeamMemberDetails } from 'models/branch';

import React from 'react';
import backgroundImage from './../../../assets/images/backgroundStockPhoto.jpg';
import OfficeInfo from 'components/General/OfficeInfo';
import {
  BranchPageSettingsData,
  PageSliceOptions
} from 'queries/collectBranchPageSettingsDuda';
import BranchPlaceholderPageSection from './BranchPlaceholderPageSection';

interface BranchLandingScreenTeamPhotoProps {
  branch: Branch;
  expertise: string[];
  iirocAdvisors: TeamMemberDetails[];
  mfdaAdvisors: TeamMemberDetails[];
  dudaData: BranchPageSettingsData;
  loading: boolean;
  errorBranch: string;
}

const BranchLandingScreenNoTeamPhoto: React.FC<BranchLandingScreenTeamPhotoProps> =
  ({
    branch,
    expertise,
    iirocAdvisors,
    mfdaAdvisors,
    dudaData,
    loading,
    errorBranch
  }) => {
    const error = (
      <Container>
        <Box sx={{ my: 3 }}>Something went wrong: {errorBranch}</Box>
      </Container>
    );

    const loadingScreen = <Box py="100px" />;

    if (loading) return loadingScreen;
    if (errorBranch) return error;

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            background:
              'linear-gradient(24.64deg, rgba(35, 41, 38, 1) 10.44%, rgba(0, 99, 49, 1) 93.74%)'
          }}
        >
          <Title
            style={{
              fontSize: '60px',
              fontWeight: 275,
              color: '#FFFFFF',
              marginTop: 150
            }}
          >{`${branch.branchDetails.branchName ?? ''}`}</Title>
          <Subtext
            style={{
              fontSize: '24px',
              color: '#FFFFFF',
              marginTop: 0,
              marginBottom: 150
            }}
          >
            {
              provinceToFull[
                branch.branchDetails.branchLocation?.province ?? ''
              ]
            }
          </Subtext>
        </Box>
        <Container>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '50px'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '53px',
                height: '4px',
                backgroundColor: 'rgba(131, 175, 92, 1)',
                marginRight: 2
              }}
            />
            <SectionHeaderTextSmall>OUR TEAM</SectionHeaderTextSmall>
          </Box>
          <SectionHeaderLarge>Meet Our Advisors</SectionHeaderLarge>
          {iirocAdvisors.length > 0 || mfdaAdvisors.length > 0 ? (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center'
              }}
            >
              {iirocAdvisors.length > 0
                ? iirocAdvisors.map((advisor) => (
                    <BranchAdvisorCardNoTeamPhoto
                      key={advisor.id}
                      advisor={advisor}
                    />
                  ))
                : null}
              {mfdaAdvisors.length > 0
                ? mfdaAdvisors.map((advisor) => (
                    <BranchAdvisorCardNoTeamPhoto
                      key={advisor.id}
                      advisor={advisor}
                    />
                  ))
                : null}
            </Box>
          ) : null}
          {branch.consultants.length > 0 ? (
            <>
              <SectionHeader
                style={{
                  justifyContent: 'center',
                  marginTop: iirocAdvisors.length > 0 ? 80 : 50,
                  marginBottom: 50,
                  paddingBottom: 0
                }}
              >
                Our Consultants
              </SectionHeader>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'space-evenly'
                }}
              >
                {branch.consultants.map((consultant) => (
                  <BranchConsultantCardNoTeamPhoto
                    key={consultant.id}
                    consultant={consultant}
                  />
                ))}
              </Box>
            </>
          ) : null}
          <SectionHeader style={{ marginBottom: 15, paddingBottom: 0 }}>
            Our Expertise
          </SectionHeader>
          <Divider
            style={{
              backgroundColor: 'rgba(131, 175, 92, 1)',
              margin: '0 0 10px'
            }}
          />
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {expertise.map((expertise) => (
              <ExpertisePill key={expertise}>{expertise}</ExpertisePill>
            ))}
          </Box>
          <BranchInfoText>
            {branch.branchDetails.branchIntro ??
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet, et mattis vivamus bibendum et libero mattis. Gravida lacus morbi aliquam dui. Duis nunc, massa varius justo. Eu pharetra sit risus nibh eleifend ultrices egestas. Nulla sollicitudin nulla justo id tristique a etiam potenti ullamcorper.'}
          </BranchInfoText>
        </Container>
        <BranchLandingPhotoContainer>
          <img src={backgroundImage} alt="Advisor" />
          <OurDifferenceGreenBackground />
          <OurDifferenceDescriptionContainer>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '50px',
                paddingLeft: '32px'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  width: '53px',
                  height: '4px',
                  backgroundColor: 'rgba(131, 175, 92, 1)',
                  marginRight: 2
                }}
              />
              <SectionHeaderTextSmall style={{ color: 'rgb(255, 255, 255)' }}>
                OUR TEAM
              </SectionHeaderTextSmall>
            </Box>
            <OurDifferenceDescription>
              Lorem ipsum dolor set amit consectetur adalipscing massa varius
              justorisus nibh eleifend ultrices egestas. Nulla sollicitudin
              nulla justo id tristique a etiam potenti ullamcorper.
            </OurDifferenceDescription>
          </OurDifferenceDescriptionContainer>
        </BranchLandingPhotoContainer>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <OfficeInfo
            props={{
              branchName: branch.branchDetails.branchName ?? '',
              address: branch.branchDetails.branchLocation?.address ?? '',
              address2: branch.branchDetails.branchLocation?.address2 ?? '',
              city: branch.branchDetails.branchLocation?.city ?? '',
              province: branch.branchDetails.branchLocation?.province ?? '',
              postalCode: branch.branchDetails.branchLocation?.postalCode ?? '',
              phone: branch.branchDetails.branchLocation?.officePhone ?? '',
              website: branch.branchDetails.branchSlug ?? '',
              officeHours: {
                Sunday: branch.branchDetails.officeHours
                  ? JSON.parse(branch.branchDetails.officeHours)['Sunday']
                  : '',
                Monday: branch.branchDetails.officeHours
                  ? JSON.parse(branch.branchDetails.officeHours)['Monday']
                  : '',
                Tuesday: branch.branchDetails.officeHours
                  ? JSON.parse(branch.branchDetails.officeHours)['Tuesday']
                  : '',
                Wednesday: branch.branchDetails.officeHours
                  ? JSON.parse(branch.branchDetails.officeHours)['Wednesday']
                  : '',
                Thursday: branch.branchDetails.officeHours
                  ? JSON.parse(branch.branchDetails.officeHours)['Thursday']
                  : '',
                Friday: branch.branchDetails.officeHours
                  ? JSON.parse(branch.branchDetails.officeHours)['Friday']
                  : '',
                Saturday: branch.branchDetails.officeHours
                  ? JSON.parse(branch.branchDetails.officeHours)['Saturday']
                  : ''
              }
            }}
            style={{ marginTop: 150, marginBottom: 250 }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {dudaData['Custom Content'][0] === PageSliceOptions.On ? (
            <BranchPlaceholderPageSection sectionName="customContent" />
          ) : null}
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {dudaData['Latest Videos'][0] === PageSliceOptions.On ? (
            <BranchPlaceholderPageSection sectionName="latestVideos" />
          ) : null}
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {dudaData['Podcast Slice'][0] === PageSliceOptions.On ? (
            <BranchPlaceholderPageSection sectionName="podcastSlice" />
          ) : null}
        </Box>
      </Box>
    );
  };

export default BranchLandingScreenNoTeamPhoto;
