/* eslint-disable  @typescript-eslint/quotes */
const sourceBoxes = [
  {
    name: 'source1',
    id: 'referred',
    label: 'I was referred by a friend'
  },
  {
    name: 'source2',
    id: 'google',
    label: 'Google'
  },
  {
    name: 'source3',
    id: 'social',
    label: 'Social Network'
  },
  {
    name: 'source4',
    id: 'event',
    label: 'Event'
  },
  {
    name: 'source5',
    id: 'sourceOther',
    label:
      'Other (please indicate any additional information in the comments field below.)'
  }
];

const interestBoxes = [
  {
    name: 'interest1',
    id: 'portfolio',
    label: 'Managing my portfolio'
  },
  {
    name: 'interest2',
    id: 'retirementPlanning',
    label: 'Retirement and income planning'
  },
  {
    name: 'interest3',
    id: 'mortgageFree',
    label: 'Becoming mortgage free faster'
  },
  {
    name: 'interest4',
    id: 'estatePlanning',
    label: 'Planning my estate'
  },
  {
    name: 'interest5',
    id: 'childEducation',
    label: "Saving for my child's education"
  },
  {
    name: 'interest6',
    id: 'taxPlanning',
    label: 'Tax planning'
  },
  {
    name: 'interest7',
    id: 'interestOther',
    label:
      'Other (please indicate any additional information in the comments field below.)'
  }
];

const advisorType = [
  {
    name: 'advisorType',
    id: 'IIROC',
    label: 'IIROC'
  },
  {
    name: 'advisorType',
    id: 'MFDA',
    label: 'MFDA'
  }
];
const provinces_codes = [
  'AB',
  'BC',
  'MB',
  'NB',
  'NL',
  'NS',
  'NT',
  'NU',
  'ON',
  'PE',
  'QC',
  'SK'
];

const provinces = provinces_codes.map((province) => ({
  value: province,
  label: province
}));

export { sourceBoxes, interestBoxes, advisorType, provinces, provinces_codes };
