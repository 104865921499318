import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

const Icon: React.FC<{
  text?: string | number;
  handleClick?: () => void;
  image: any;
  alt?: string;
  uniqueId?: string;
  website?: string;
  iconWidth?: string;
  phone?: string;
}> = ({
  text,
  image,
  alt,
  website,
  handleClick,
  iconWidth,
  phone,
  uniqueId
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

  return (
    <IconContainer>
      <img alt={alt} width={iconWidth ?? 28} src={image} />
      {website ? (
        <a
          id={uniqueId}
          onClick={handleClick}
          href={`https://${website}`}
          target="_blank"
        >
          {website}
        </a>
      ) : null}
      {phone && isMobile ? (
        <>
          <a id="clickToCall" onClick={handleClick} href={`tel:${phone}`}>
            {phone}
          </a>
        </>
      ) : null}
      {phone && !isMobile ? (
        <>
          <Text>{phone}</Text>
        </>
      ) : null}
      {text ? (
        <>
          <Text>{text}</Text>
        </>
      ) : null}
    </IconContainer>
  );
};

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  img {
    height: auto;
    @media screen and (max-width: 768px) {
      width: 22px;
    }
  }
  p {
    font-size: 18px;
    letter-spacing: 0.5px;
    padding-left: 15px;
    margin: 0px;
    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
    @media screen and (min-width: 768px) and (max-width: 992px) {
      font-size: 18px;
    }
  }
  a {
    color: #046333;
    width: 85%;
    word-break: break-word;
    padding-left: 15px;
    text-decoration: underline;
    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
    @media screen and (min-width: 768px) and (max-width: 992px) {
      font-size: 18px;
    }
  }
`;

const Text = styled.p`
  color: #000000;
  font-size: 18px;
  color: #000000;
`;

export default Icon;
