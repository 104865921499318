import { createClient } from 'contentful';
import { AdvisorHybrid } from 'models/advisor';
import { ContentulAdvisorCollectionResponse } from 'models/contentfulResponse';
import fieldMapper from './helpers/fieldMapper';

const collect = async () => {
  const contentfulClient = createClient({
    accessToken: `${process.env.REACT_APP_CONTENTFUL_SECRET_TOKEN}`,
    space: `${process.env.REACT_APP_CONTENTFUL_SPACE_ID}`,
    environment: `${process.env.REACT_APP_CONTENTFUL_ENVIRONMENT}`
  });
  return contentfulClient
    .getEntries({
      content_type: 'users',
      limit: 1000,
      select: [
        'fields.advisorTeamName',
        'fields.location',
        'fields.showAdviceYourWay',
        'fields.phone',
        'fields.website',
        'fields.teamDescription',
        'fields.address',
        'fields.city',
        'fields.postalCode',
        'fields.province',
        'fields.teamMembers',
        'fields.facebookLink',
        'fields.linkedinLink',
        'fields.slug',
        'fields.officeHours',
        'fields.friendlyTeamName',
        'fields.officeLocation'
      ]
    })

    .then((entries) => {
      const allAdvisors: AdvisorHybrid[] = [];
      entries.items.forEach(
        //@ts-expect-error here assigning attributes to unknown type is giving me shit
        (rawContentfulAdvisorTeam: ContentulAdvisorCollectionResponse) => {
          rawContentfulAdvisorTeam?.fields?.teamMembers?.forEach(
            (flaggedAdvisor: any) => {
              if (flaggedAdvisor?.fields?.enableAdvisorLocator) {
                const mappedFields = fieldMapper(rawContentfulAdvisorTeam);

                allAdvisors.push({
                  teamFields: {
                    name: mappedFields.name,
                    friendlyTeamName: mappedFields.friendlyTeamName,
                    lat: mappedFields.lat,
                    lon: mappedFields.lon,
                    phone: mappedFields.phone,
                    adviceYourWay: mappedFields.adviceYourWay,
                    website: mappedFields.website,
                    facebook: mappedFields.facebook,
                    linkedin: mappedFields.linkedin,
                    descriptionParagraph: mappedFields.descriptionParagraph,
                    address: mappedFields.address,
                    city: mappedFields.city,
                    postalCode: mappedFields.postalCode,
                    province: mappedFields.province,
                    slug: mappedFields.slug,
                    officeHours: mappedFields.officeHours,
                    advisorTeamInResultSet: mappedFields.advisorTeamInResultSet,
                    advisorTeam: mappedFields.advisorTeam
                  },
                  advisorFields: {
                    description:
                      flaggedAdvisor?.fields?.description?.content[0]
                        ?.content[0]?.value,
                    photo: flaggedAdvisor?.fields?.image?.fields?.file?.url,
                    name: flaggedAdvisor?.fields?.name,
                    title: flaggedAdvisor?.fields?.title,
                    locatorSlug: flaggedAdvisor?.fields?.locatorSlug,
                    email: flaggedAdvisor?.fields?.email,
                    adviceYourWayAdvisor:
                      flaggedAdvisor?.fields?.adviceYourWayAdvisor,
                    bookAnAppointment:
                      flaggedAdvisor?.fields?.bookAnAppointment,
                    enableAdvisorLocator:
                      flaggedAdvisor?.fields?.enableAdvisorLocator,
                    privateWealthAdvisor: Boolean(
                      flaggedAdvisor?.fields?.privateWealthAdvisor
                    ),
                    facebook: flaggedAdvisor?.fields?.facebook,
                    linkedIn: flaggedAdvisor?.fields?.linkedIn,
                    typeOfAdvisor: flaggedAdvisor?.fields?.typeOfAdvisor,
                    id: flaggedAdvisor?.sys?.id,
                    comments: flaggedAdvisor?.fields?.comments,
                    expertise: flaggedAdvisor?.fields?.expertise,
                    primaryOffice: flaggedAdvisor?.fields?.primaryOffice
                      ? fieldMapper(flaggedAdvisor?.fields?.primaryOffice)
                      : undefined
                  }
                });
              }
            }
          );
        }
      );

      return allAdvisors;
    });
};

export default collect;
