import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { AdvisorHybrid } from 'models/advisor';
import Button from 'components/General/Button';
import ExpertiseCard from 'components/General/ExpertiseCard';
import TeamMember from 'components/TeamMember';
import Icon from 'components/General/Icon';
import ContactModal from 'components/Screens/ContactModal';

import AYWButton from 'assets/images/AYW_Button.svg';
import image from 'assets/images/placeholder.svg';
import phoneIcon from 'assets/images/phone.svg';
import webIcon from 'assets/images/web.svg';
import FacebookIcon from 'assets/images/Facebook.svg';
import LinkedInIcon from 'assets/images/LinkedIn.svg';
import backArrow from 'assets/images/backArrow.svg';
import bannerFlair from 'assets/images/bannerFlair.svg';
import { ReactGA4Event } from 'utilities/reactGA4Event';
import OfficeInfo from 'components/General/OfficeInfo';

const AdvisorScreen: React.FC<{
  selectedAdvisor: AdvisorHybrid;
  goBack: () => void;
}> = ({ selectedAdvisor, goBack }) => {
  const [modalActive, setModalActive] = useState<boolean>(false);
  console.log(selectedAdvisor);
  useEffect(() => {
    document.getElementById('advisorfinder-appended-div')?.scrollIntoView();
  }, []);

  const getAdvisorName = () => {
    if (
      selectedAdvisor.advisorFields.primaryOffice?.friendlyTeamName ===
      selectedAdvisor.advisorFields.name
    ) {
      return null;
    }
    if (
      selectedAdvisor.advisorFields.primaryOffice?.friendlyTeamName !==
      selectedAdvisor.advisorFields.name
    ) {
      return selectedAdvisor.advisorFields.primaryOffice?.friendlyTeamName;
    }
    return selectedAdvisor.teamFields.friendlyTeamName;
  };

  const getField = (field: string) => {
    if (
      selectedAdvisor.advisorFields.primaryOffice &&
      // @ts-expect-error
      selectedAdvisor.advisorFields.primaryOffice[`${field}`]
    ) {
      // @ts-expect-error
      return selectedAdvisor.advisorFields.primaryOffice[`${field}`];
    }
    // @ts-expect-error
    return selectedAdvisor.advisorFields[`${field}`];
  };

  const getAddress = () => (
    <>
      {' '}
      {getField('address')}
      <br />
      {`${getField('city')} ${getField('province')} ${getField('postalCode')}`}
    </>
  );

  const getURL = () =>
    `https://www.google.com/maps/search/?api=1&query=${getField(
      'address'
    )}+${getField('city')}+${getField('province')}+${getField(
      'postalCode'
    )}+IPC+Investment+Corporation`;

  return (
    <Container>
      <HeroContainer>
        <Controls onClick={goBack}>
          <Icon alt="Back Arrow" iconWidth="20" image={backArrow} />
          <Button type="button" theme="Back" text="Go back" />
        </Controls>
        <HeroDetails>
          <HeroColumn>
            <PhotoInfo>
              <Photo
                alt={`${getField('name')}'s photograph`}
                src={selectedAdvisor.advisorFields.photo || image}
              />
            </PhotoInfo>
          </HeroColumn>
          <HeroColumn>
            <PersonInfo>
              {getField('adviceYourWay') ? (
                <img width="169" alt="Advice Your Way" src={AYWButton} />
              ) : null}
              <HeroText>{selectedAdvisor.advisorFields.name}</HeroText>
              <Title>{selectedAdvisor.advisorFields.title}</Title>

              <FriendlyTeamName>{getAdvisorName()}</FriendlyTeamName>
              <Address
                onClick={() => {
                  ReactGA4Event('event', 'click', {
                    link_category: 'find_an_advisor',
                    advisor: `${selectedAdvisor.advisorFields.name} -- ${selectedAdvisor.advisorFields.email}`,
                    link_id: 'viewMap',
                    link_text: `${getField('address')} -- ${getField(
                      'city'
                    )} ${getField('province')} ${getField('postalCode')}`,
                    link_url: `${getURL()}`
                  });
                }}
                id="viewMap"
                target="_blank"
                href={getURL()}
              >
                {getAddress()}
              </Address>
              <div style={{ backgroundColor: '#EBF5EE', width: 'fit-content' }}>
                <Button
                  type="button"
                  handleClick={() => {
                    if (selectedAdvisor.advisorFields.bookAnAppointment) {
                      ReactGA4Event('event', 'internal_click', {
                        link_category: 'find_an_advisor',
                        advisor: `${selectedAdvisor.advisorFields.name} -- ${selectedAdvisor.advisorFields.email}`,
                        link_id: 'openAdvisorPopup',
                        link_text: 'Book an Appointment',
                        link_url: 'openAdvisorPopup'
                      });
                      window.open(
                        `${selectedAdvisor.advisorFields.bookAnAppointment}`,
                        '_blank'
                      );
                    } else {
                      ReactGA4Event('event', 'internal_click', {
                        link_category: 'find_an_advisor',
                        advisor: `${selectedAdvisor.advisorFields.name} -- ${selectedAdvisor.advisorFields.email}`,
                        link_id: 'openAdvisorPopup',
                        link_text: 'Start a conversation',
                        link_url: 'openAdvisorPopup'
                      });
                      setModalActive(true);
                    }
                  }}
                  theme="CTA"
                  uniqueId="openAdvisorPopup"
                  text={
                    selectedAdvisor.advisorFields.bookAnAppointment
                      ? 'Book an Appointment'
                      : 'Start a conversation'
                  }
                />
              </div>
            </PersonInfo>
          </HeroColumn>
          <HeroColumn>
            <Info>
              {/* <SmallTitle>Contact me virtually or in person</SmallTitle> */}
              <div style={{ marginBottom: '24px' }}>
                <Icon
                  handleClick={() =>
                    ReactGA4Event('event', 'click', {
                      link_category: 'find_an_advisor',
                      advisor: `${selectedAdvisor.advisorFields.name} -- ${selectedAdvisor.advisorFields.email}`,
                      link_id: 'clickToCall',
                      link_url: `${getField('phone')}`
                    })
                  }
                  alt=""
                  image={phoneIcon}
                  phone={getField('phone')}
                />
              </div>
              <div style={{ marginBottom: '24px' }}>
                <Icon
                  uniqueId="websiteClick"
                  handleClick={() =>
                    ReactGA4Event('event', 'click', {
                      link_category: 'find_an_advisor',
                      advisor: `${selectedAdvisor.advisorFields.name} -- ${selectedAdvisor.advisorFields.email}`,
                      link_id: 'websiteClick',
                      link_url: `${getField('website')}`
                    })
                  }
                  alt=""
                  image={webIcon}
                  website={getField('website')}
                />
              </div>
              {selectedAdvisor.advisorFields.facebook ||
              selectedAdvisor.advisorFields.linkedIn ? (
                <Socials>
                  {selectedAdvisor.advisorFields.facebook ? (
                    <a
                      id="facebookClick"
                      href={selectedAdvisor.advisorFields.facebook}
                      target="_blank"
                      style={{ marginRight: '10px' }}
                      onClick={() => {
                        ReactGA4Event('event', 'click', {
                          link_category: 'find_an_advisor',
                          advisor: `${selectedAdvisor.advisorFields.name} -- ${selectedAdvisor.advisorFields.email}`,
                          link_id: 'facebookClick',
                          link_url: `${selectedAdvisor.advisorFields.facebook}`
                        });
                      }}
                    >
                      <img alt="Facebook Icon" src={FacebookIcon} />
                    </a>
                  ) : null}
                  {selectedAdvisor.advisorFields.linkedIn ? (
                    <a
                      id="linkedinClick"
                      href={selectedAdvisor.advisorFields.linkedIn}
                      target="_blank"
                      onClick={() => {
                        ReactGA4Event('event', 'click', {
                          link_category: 'find_an_advisor',
                          advisor: `${selectedAdvisor.advisorFields.name} -- ${selectedAdvisor.advisorFields.email}`,
                          link_id: 'linkedinClick',
                          link_url: `${selectedAdvisor.advisorFields.linkedIn}`
                        });
                      }}
                    >
                      <img alt="LinkedIn Icon" src={LinkedInIcon} />
                    </a>
                  ) : null}
                </Socials>
              ) : null}
            </Info>
          </HeroColumn>
        </HeroDetails>
      </HeroContainer>

      <BannerFlair />

      <DetailsContainer>
        <SmallTitle>My Expertise</SmallTitle>
        <Border />
        <ExpertiseContainer>
          {selectedAdvisor?.advisorFields?.expertise
            ? selectedAdvisor?.advisorFields?.expertise.map((card, index) => {
                return <ExpertiseCard text={card} key={`${index}-${card}`} />;
              })
            : null}
        </ExpertiseContainer>
        <Text>{getField('descriptionParagraph')}</Text>
        <Team>
          <SmallTitle>Our Team</SmallTitle>
          <TeamList>
            {selectedAdvisor?.teamFields?.advisorTeam &&
            selectedAdvisor?.teamFields?.advisorTeam.length > 0
              ? selectedAdvisor.teamFields.advisorTeam.map((teamMember) => {
                  return (
                    <TeamMember
                      key={`${teamMember.name}`}
                      photo={teamMember.photo}
                      name={teamMember.name}
                      credentials={teamMember.title}
                    />
                  );
                })
              : null}
          </TeamList>
        </Team>
        <OfficeInfo
          props={{
            branchName: getField('friendlyTeamName'),
            address: getField('address'),
            address2: '',
            city: getField('city'),
            province: getField('province'),
            postalCode: getField('postalCode'),
            phone: getField('phone'),
            website: getField('website'),
            officeHours: {
              Sunday: selectedAdvisor.advisorFields.primaryOffice
                ? selectedAdvisor.advisorFields.primaryOffice.officeHours
                    ?.Sunday
                : selectedAdvisor.teamFields.officeHours?.Sunday,
              Monday: selectedAdvisor.advisorFields.primaryOffice
                ? selectedAdvisor.advisorFields.primaryOffice.officeHours
                    ?.Monday
                : selectedAdvisor.teamFields.officeHours?.Monday,
              Tuesday: selectedAdvisor.advisorFields.primaryOffice
                ? selectedAdvisor.advisorFields.primaryOffice.officeHours
                    ?.Tuesday
                : selectedAdvisor.teamFields.officeHours?.Tuesday,
              Wednesday: selectedAdvisor.advisorFields.primaryOffice
                ? selectedAdvisor.advisorFields.primaryOffice.officeHours
                    ?.Wednesday
                : selectedAdvisor.teamFields.officeHours?.Wednesday,
              Thursday: selectedAdvisor.advisorFields.primaryOffice
                ? selectedAdvisor.advisorFields.primaryOffice.officeHours
                    ?.Thursday
                : selectedAdvisor.teamFields.officeHours?.Thursday,
              Friday: selectedAdvisor.advisorFields.primaryOffice
                ? selectedAdvisor.advisorFields.primaryOffice.officeHours
                    ?.Friday
                : selectedAdvisor.teamFields.officeHours?.Friday,
              Saturday: selectedAdvisor.advisorFields.primaryOffice
                ? selectedAdvisor.advisorFields.primaryOffice.officeHours
                    ?.Saturday
                : selectedAdvisor.teamFields.officeHours?.Saturday
            }
          }}
        />
      </DetailsContainer>
      <Controls onClick={goBack}>
        <Icon alt="Back Arrow" iconWidth="20" image={backArrow} />
        <Button type="button" theme="Back" text="Go back" />
      </Controls>
      <ContactModal
        modalActive={modalActive}
        setModalActive={setModalActive}
        teamName={selectedAdvisor.advisorFields.name}
        advisorRecipient={selectedAdvisor.advisorFields.email}
      />
    </Container>
  );
};

const Container = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-top: 150px;
  @media screen and (max-width: 980px) {
    padding-top: 90px;
  }
`;

const Controls = styled.div`
  display: flex;
  background-color: transparent;
  padding: 30px 0px;
  margin: 0px 30px;
  margin-right: auto;
  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: 767px) {
    padding: 10px 0px;
    margin: 0px 15px;
    margin-right: auto;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    padding: 10px 0px;
  }
`;

const HeroContainer = styled.div`
  background-color: #f0f6f3;
  width: 100%;
  min-height: 536px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 767px) {
    padding-bottom: 30px;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    min-height: 400px;
  }
`;

const HeroDetails = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  width: 80%;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    width: 90%;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    width: 90%;
  }
  padding-bottom: 30px;
`;

const BannerFlair = styled.div`
  height: 68px;
  width: 100%;
  background-image: url(${bannerFlair});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const HeroColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;
  @media screen and (max-width: 767px) {
    width: 100%;
    justify-content: center;
  }
`;

const PersonInfo = styled.div`
  padding: 30px 30px 30px 0px;
  min-height: 300px;
  flex-direction: column;
  display: flex;
  padding: 0px 10px 0px 20px;
  justify-content: space-evenly;
  margin-left: 30px;
  @media screen and (max-width: 767px) {
    padding: 10px 0px 10px 0px;
    min-height: 260px;
    align-items: center;
    margin: 0px;
  }
`;

const Info = styled.div`
  padding: 30px;

  background-color: #ffffff;
  border: 1px solid #d4e7ed;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  justify-content: space-evenly;
  box-shadow: 0px 4px 12px rgba(37, 47, 35, 0.15);
  @media screen and (max-width: 767px) {
    min-height: 160px;
    padding: 20px;
    justify-content: space-between;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    min-height: 210px;
  }
`;

const ExpertiseContainer = styled.div`
  display: flex;
  margin-bottom: 0px;
  height: auto;
  width: 100%;
  flex-wrap: wrap;
`;

/* Text */

const SmallTitle = styled.h3`
  font-family: Poppins;
  font-weight: 200;
  font-size: 32px;
  line-height: 135%;
  width: 90%;
  margin: 0px;
  margin-bottom: 25px;
  @media screen and (max-width: 767px) {
    font-size: 25px;
    line-height: 125%;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    font-size: 26px;
    line-height: 125%;
  }
`;

const Text = styled.p`
  font-size: 20px;
  line-height: 40px;
  padding: 40px 0px;
  font-family: Gill Sans, sans-serif;
  @media screen and (max-width: 767px) {
    font-size: 18px;
    line-height: 32px;
    padding: 20px 0px;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    padding: 0px 0px;
  }
`;

const HeroText = styled.h2`
  font-size: 44px;
  text-transform: none !important;
  text-align: left;
  padding: 0px;
  line-height: 60px;
  letter-spacing: -1px;
  margin: 0px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 200;
  color: #252f23;
  margin-top: 5px;
  @media screen and (max-width: 767px) {
    font-size: 32px;
    text-align: center;
    margin-top: 0px;
    line-height: 42px;
    padding: 0px 0px;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    font-size: 36px;
  }
  line-height: 44px;
`;

const PhotoInfo = styled.div`
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 767px) {
    justify-content: center;
  }
`;

const Address = styled.a`
  line-height: 26.4px;
  letter-spacing: 0.5px;
  margin: 0px;
  color: black;
  text-decoration: auto;
  margin-bottom: 20px;
  font-size: 18px;
  @media screen and (max-width: 767px) {
    text-align: center;
  }
  &:hover {
    cursor: pointer;
  }
`;

const FriendlyTeamName = styled.p`
  line-height: 26.4px;
  letter-spacing: 0.5px;
  font-family: Gill Sans;
  margin: 0px;
  @media screen and (max-width: 767px) {
    text-align: center;
  }
  margin-top: 30px;
  font-size: 18px;
  color: #000000;
`;

const Title = styled.p`
  line-height: 26.4px;
  color: #000000;
  letter-spacing: 0.5px;
  margin: 0px;
  @media screen and (max-width: 767px) {
    text-align: center;
  }
  margin-top: 20px;
  font-weight: 600;
  font-size: 20px;
`;

/* End Text */

const Photo = styled.img`
  width: 300px;
  height: auto
  filter: drop-shadow(0px 4px 12px rgba(37, 47, 35, 0.15));
  @media screen and (max-width: 767px) {
    width: 240px;
    height: 240px;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    width: 260px;
    height: 260px;
  }
`;

const Socials = styled.div`
  display: flex;
  justify-content: space-between;
  width: 25%;
  align-items: center;
`;

const Border = styled.hr`
  background-color: #76be43;
  height: 4px;
  border: unset;
  margin-bottom: 20px;
`;

const Team = styled.div`
  padding-bottom: 40px;
  @media screen and (max-width: 767px) {
    padding-bottom: 20px;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    padding-bottom: 20px;
  }
`;

const TeamList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const DetailsContainer = styled.div`
  padding-top: 30px;
  width: 65%;

  margin: 0 auto;
  @media screen and (max-width: 767px) {
    width: 90%;
  }
`;

export default AdvisorScreen;
