import axios from 'axios';

interface DudaReturn {
  name: string;
  values: {
    id: string;
    page_item_url: string;
    data: BranchPageSettingsData;
  }[];
}

export enum PageSliceOptions {
  On = 'On',
  Off = 'Off'
}

export enum BranchTemplateOptions {
  TeamPhoto = 'Team Photo',
  NoTeamPhoto = 'No Team Photo'
}

export interface BranchPageSettingsData {
  'Branch Name': string;
  'Branch Template': BranchTemplateOptions[];
  'Custom Content': PageSliceOptions[];
  'Latest Videos': PageSliceOptions[];
  'Podcast Slice': PageSliceOptions[];
}

const getCollection = async (
  siteId: string,
  collectionName: string,
  branchSlug: string
): Promise<BranchPageSettingsData | null> => {
  return axios({
    method: 'get',
    url: `https://efhr3zjik0.execute-api.us-east-1.amazonaws.com/prod/collection?siteName=${siteId}&collectionName=${collectionName}`
  })
    .then((res) => {
      const result = res.data as DudaReturn;
      const resultForBranch = result.values.filter(
        (value) => value.page_item_url === branchSlug
      )[0].data;
      return resultForBranch;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
};

export default getCollection;
