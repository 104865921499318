import {
  ContentulAdvisorCollectionResponse,
  ContentulTeamMemberCollectionResponse
} from 'models/contentfulResponse';
const fieldMapper = function (
  overrideOffice: ContentulAdvisorCollectionResponse
) {
  return {
    name: overrideOffice?.fields?.advisorTeamName,
    friendlyTeamName: overrideOffice?.fields?.friendlyTeamName,
    lat: Number(overrideOffice?.fields?.location?.lat),
    lon: Number(overrideOffice?.fields?.location?.lon),
    phone: overrideOffice?.fields?.phone,
    adviceYourWay: Boolean(overrideOffice?.fields?.showAdviceYourWay),
    website: overrideOffice?.fields?.website,
    facebook: overrideOffice?.fields?.facebookLink,
    linkedin: overrideOffice?.fields?.linkedinLink,
    descriptionParagraph:
      overrideOffice?.fields?.teamDescription?.content[0]?.content[0].value,
    address: overrideOffice?.fields?.address,
    city: overrideOffice?.fields?.city,
    postalCode: overrideOffice?.fields?.postalCode,
    province: overrideOffice?.fields?.province,
    slug: overrideOffice?.fields?.slug,
    officeHours: {
      Monday: overrideOffice.fields?.officeHours?.[0].Monday || undefined,
      Tuesday: overrideOffice.fields?.officeHours?.[0].Tuesday || undefined,
      Wednesday: overrideOffice.fields?.officeHours?.[0].Wednesday || undefined,
      Thursday: overrideOffice.fields?.officeHours?.[0].Thursday || undefined,
      Friday: overrideOffice.fields?.officeHours?.[0].Friday || undefined,
      Saturday: overrideOffice.fields?.officeHours?.[0].Saturday || undefined,
      Sunday: overrideOffice.fields?.officeHours?.[0].Sunday || undefined
    },

    advisorTeamInResultSet: overrideOffice?.fields?.teamMembers?.map(
      (teamMember: ContentulTeamMemberCollectionResponse) => {
        return {
          description:
            teamMember?.fields?.description?.content[0]?.content[0]?.value,
          photo: teamMember?.fields?.image?.fields?.file?.url,
          name: teamMember?.fields?.name,
          title: teamMember?.fields?.title,
          locatorSlug: teamMember?.fields?.locatorSlug,
          email: teamMember?.fields?.email,
          adviceYourWayAdvisor: teamMember?.fields?.adviceYourWayAdvisor,
          bookAnAppointment: teamMember?.fields?.bookAnAppointment,
          enableAdvisorLocator: teamMember?.fields?.enableAdvisorLocator,
          privateWealthAdvisor: Boolean(
            teamMember?.fields?.privateWealthAdvisor
          ),
          facebook: teamMember?.fields?.facebook,
          linkedIn: teamMember?.fields?.linkedIn,
          typeOfAdvisor: teamMember?.fields?.typeOfAdvisor,
          id: teamMember?.sys?.id,
          comments: teamMember?.fields?.comments,
          expertise: teamMember?.fields?.expertise
        };
      }
    ),
    advisorTeam: overrideOffice?.fields?.teamMembers?.map(
      (teamMember: ContentulTeamMemberCollectionResponse) => {
        return {
          description:
            teamMember?.fields?.description?.content[0]?.content[0]?.value,
          photo: teamMember?.fields?.image?.fields?.file?.url,
          name: teamMember?.fields?.name,
          title: teamMember?.fields?.title,
          locatorSlug: teamMember?.fields?.locatorSlug,
          email: teamMember?.fields?.email,
          adviceYourWayAdvisor: teamMember?.fields?.adviceYourWayAdvisor,
          bookAnAppointment: teamMember?.fields?.bookAnAppointment,
          enableAdvisorLocator: teamMember?.fields?.enableAdvisorLocator,
          privateWealthAdvisor: Boolean(
            teamMember?.fields?.privateWealthAdvisor
          ),
          facebook: teamMember?.fields?.facebook,
          linkedIn: teamMember?.fields?.linkedIn,
          typeOfAdvisor: teamMember?.fields?.typeOfAdvisor,
          id: teamMember?.sys?.id,
          comments: teamMember?.fields?.comments,
          expertise: teamMember?.fields?.expertise
        };
      }
    )
  };
};

export { fieldMapper as default };
