import { Box } from '@mui/material';
import {
  AdvisorSectionDescription,
  BranchInfoText,
  BranchLandingPhotoContainer,
  Container,
  Divider,
  ExpertisePill,
  OurDifferenceDescription,
  OurDifferenceDescriptionContainer,
  OurDifferenceGreenBackground,
  SectionHeader,
  SectionHeaderLarge,
  SectionHeaderTextSmall,
  Subtext,
  TeamPhotoContainer,
  Title
} from 'components/General/StyledComponents';
import BranchAdvisorCardTeamPhoto from 'components/General/branch/BranchAdvisorCardTeamPhoto';
import BranchConsultantCardTeamPhoto from 'components/General/branch/BranchConsultantCardTeamPhoto';
import { provinceToFull } from 'constants/provinceToFull';
import { Branch, TeamMemberDetails } from 'models/branch';

import React from 'react';
import backgroundImage from './../../../assets/images/backgroundStockPhoto.jpg';
import teamPhoto from './../../../assets/images/placeholderTeamPhoto.jpg';
import OfficeInfo from 'components/General/OfficeInfo';
import {
  BranchPageSettingsData,
  PageSliceOptions
} from 'queries/collectBranchPageSettingsDuda';
import BranchPlaceholderPageSection from './BranchPlaceholderPageSection';

interface BranchLandingScreenTeamPhotoProps {
  branch: Branch;
  expertise: string[];
  iirocAdvisors: TeamMemberDetails[];
  mfdaAdvisors: TeamMemberDetails[];
  dudaData: BranchPageSettingsData;
  loading: boolean;
  errorBranch: string;
}

const BranchLandingScreenTeamPhoto: React.FC<BranchLandingScreenTeamPhotoProps> =
  ({
    branch,
    expertise,
    iirocAdvisors,
    mfdaAdvisors,
    dudaData,
    loading,
    errorBranch
  }) => {
    const error = (
      <Container>
        <Box sx={{ my: 3 }}>Something went wrong: {errorBranch}</Box>
      </Container>
    );

    const loadingScreen = <Box py="100px" />;

    if (loading) return loadingScreen;
    if (errorBranch) return error;
    console.log(dudaData);
    console.log(dudaData['Latest Videos'][0]);
    console.log(dudaData['Latest Videos'][0] === PageSliceOptions.On);
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Container style={{ minWidth: 'none' }}>
          <Title style={{ fontSize: '60px', fontWeight: 275 }}>{`${
            branch.branchDetails.branchName ?? ''
          }`}</Title>
          <Subtext
            style={{
              fontSize: '24px',
              color: 'rgba(68, 68, 68, 1)',
              marginTop: 0
            }}
          >
            {
              provinceToFull[
                branch.branchDetails.branchLocation?.province ?? ''
              ]
            }
          </Subtext>
          <TeamPhotoContainer>
            <img src={teamPhoto} alt="Team Photo" />
          </TeamPhotoContainer>
          <SectionHeader style={{ marginBottom: 15, paddingBottom: 0 }}>
            Our Expertise
          </SectionHeader>
          <Divider
            style={{
              backgroundColor: 'rgba(131, 175, 92, 1)',
              margin: '0 0 10px'
            }}
          />
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {expertise.map((expertise) => (
              <ExpertisePill key={expertise}>{expertise}</ExpertisePill>
            ))}
          </Box>
          <BranchInfoText>
            {branch.branchDetails.branchIntro ??
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet, et mattis vivamus bibendum et libero mattis. Gravida lacus morbi aliquam dui. Duis nunc, massa varius justo. Eu pharetra sit risus nibh eleifend ultrices egestas. Nulla sollicitudin nulla justo id tristique a etiam potenti ullamcorper.'}
          </BranchInfoText>
        </Container>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(245, 248, 249, 1)'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '50px'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '53px',
                height: '4px',
                backgroundColor: 'rgba(131, 175, 92, 1)',
                marginRight: 2
              }}
            />
            <SectionHeaderTextSmall>OUR TEAM</SectionHeaderTextSmall>
          </Box>
          <SectionHeaderLarge>Meet Our Advisors</SectionHeaderLarge>
          {iirocAdvisors.length > 0 ? (
            <>
              <SectionHeader style={{ justifyContent: 'center' }}>
                IIROC
              </SectionHeader>
              <AdvisorSectionDescription
                style={{ marginTop: 8, marginBottom: 8 }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet,
                et mattis vivamus bibendum et libero mattis.
              </AdvisorSectionDescription>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center'
                }}
              >
                {iirocAdvisors.map((advisor) => (
                  <BranchAdvisorCardTeamPhoto
                    key={advisor.id}
                    advisor={advisor}
                  />
                ))}
              </Box>
            </>
          ) : null}
          {mfdaAdvisors.length > 0 ? (
            <>
              <SectionHeader
                style={{
                  justifyContent: 'center',
                  marginTop: iirocAdvisors.length > 0 ? 80 : 50
                }}
              >
                IPC Securities Corporation&sup2;
              </SectionHeader>
              <AdvisorSectionDescription
                style={{ marginTop: 8, marginBottom: 8 }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet,
                et mattis vivamus bibendum et libero mattis.
              </AdvisorSectionDescription>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'space-evenly'
                }}
              >
                {mfdaAdvisors.map((advisor) => (
                  <BranchAdvisorCardTeamPhoto
                    key={advisor.id}
                    advisor={advisor}
                  />
                ))}
              </Box>
            </>
          ) : null}
          {branch.consultants.length > 0 ? (
            <>
              <SectionHeader
                style={{
                  justifyContent: 'center',
                  marginTop: iirocAdvisors.length > 0 ? 80 : 50,
                  marginBottom: 50,
                  paddingBottom: 0
                }}
              >
                Our Consultants
              </SectionHeader>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'space-evenly'
                }}
              >
                {branch.consultants.map((consultant) => (
                  <BranchConsultantCardTeamPhoto
                    key={consultant.id}
                    consultant={consultant}
                  />
                ))}
              </Box>
            </>
          ) : null}
          <BranchLandingPhotoContainer>
            <img src={backgroundImage} alt="Advisor" />
            <OurDifferenceGreenBackground />
            <OurDifferenceDescriptionContainer>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '50px',
                  paddingLeft: '32px'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    width: '53px',
                    height: '4px',
                    backgroundColor: 'rgba(131, 175, 92, 1)',
                    marginRight: 2
                  }}
                />
                <SectionHeaderTextSmall style={{ color: 'rgb(255, 255, 255)' }}>
                  OUR TEAM
                </SectionHeaderTextSmall>
              </Box>
              <OurDifferenceDescription>
                Lorem ipsum dolor set amit consectetur adalipscing massa varius
                justorisus nibh eleifend ultrices egestas. Nulla sollicitudin
                nulla justo id tristique a etiam potenti ullamcorper.
              </OurDifferenceDescription>
            </OurDifferenceDescriptionContainer>
          </BranchLandingPhotoContainer>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <OfficeInfo
              props={{
                branchName: branch.branchDetails.branchName ?? '',
                address: branch.branchDetails.branchLocation?.address ?? '',
                address2: branch.branchDetails.branchLocation?.address2 ?? '',
                city: branch.branchDetails.branchLocation?.city ?? '',
                province: branch.branchDetails.branchLocation?.province ?? '',
                postalCode:
                  branch.branchDetails.branchLocation?.postalCode ?? '',
                phone: branch.branchDetails.branchLocation?.officePhone ?? '',
                website: branch.branchDetails.branchSlug ?? '',
                officeHours: {
                  Sunday: branch.branchDetails.officeHours
                    ? JSON.parse(branch.branchDetails.officeHours)['Sunday']
                    : '',
                  Monday: branch.branchDetails.officeHours
                    ? JSON.parse(branch.branchDetails.officeHours)['Monday']
                    : '',
                  Tuesday: branch.branchDetails.officeHours
                    ? JSON.parse(branch.branchDetails.officeHours)['Tuesday']
                    : '',
                  Wednesday: branch.branchDetails.officeHours
                    ? JSON.parse(branch.branchDetails.officeHours)['Wednesday']
                    : '',
                  Thursday: branch.branchDetails.officeHours
                    ? JSON.parse(branch.branchDetails.officeHours)['Thursday']
                    : '',
                  Friday: branch.branchDetails.officeHours
                    ? JSON.parse(branch.branchDetails.officeHours)['Friday']
                    : '',
                  Saturday: branch.branchDetails.officeHours
                    ? JSON.parse(branch.branchDetails.officeHours)['Saturday']
                    : ''
                }
              }}
              style={{ marginTop: 150, marginBottom: 250 }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {dudaData['Custom Content'][0] === PageSliceOptions.On ? (
            <BranchPlaceholderPageSection sectionName="customContent" />
          ) : null}
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {dudaData['Latest Videos'][0] === PageSliceOptions.On ? (
            <BranchPlaceholderPageSection sectionName="latestVideos" />
          ) : null}
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {dudaData['Podcast Slice'][0] === PageSliceOptions.On ? (
            <BranchPlaceholderPageSection sectionName="podcastSlice" />
          ) : null}
        </Box>
      </Box>
    );
  };

export default BranchLandingScreenTeamPhoto;
