import { createClient } from 'contentful-management';
import { BranchPostParams } from 'models/branch';
import * as Sentry from '@sentry/react';

const accessToken = `${process.env.REACT_APP_CONTENTFUL_WRITE_ACCESS_TOKEN}`;
const space = `${process.env.REACT_APP_CONTENTFUL_SPACE_ID}`;
const environment = `${process.env.REACT_APP_CONTENTFUL_ENVIRONMENT}`;

const postBranchDetails = async (values: BranchPostParams) => {
  const { id, branchEdits } = values;

  const contentfulClient = createClient({
    accessToken
  });

  const contentful_space = await contentfulClient.getSpace(space);
  const contentful_environment = await contentful_space.getEnvironment(
    environment
  );

  try {
    const branchObject = await contentful_environment.getEntry(id, {
      select: 'fields.branchEdits'
    });

    if (!branchObject) {
      throw new Error(`Branch with id ${id} not found`);
    }

    branchObject.fields.branchEdits
      ? (branchObject.fields.branchEdits['en-US'] = branchEdits)
      : (branchObject.fields.branchEdits = { ['en-US']: branchEdits });

    const updatedBranch = await branchObject.update();
    await updatedBranch.publish();
  } catch (err) {
    console.error(err);
    Sentry.captureException(err);
    throw err;
  }
};

export default postBranchDetails;
