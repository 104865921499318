import React from 'react';
import styled from 'styled-components';

import image from 'assets/images/placeholder.svg';
import { TeamMemberDetails } from 'models/branch';

const BranchConsultantCardTeamPhoto: React.FC<{
  consultant: TeamMemberDetails;
}> = ({ consultant }) => {
  return (
    <BranchAdvisorCardContainer>
      <PhotoContainer>
        <img src={consultant.photo || image} alt="Advisor" />
      </PhotoContainer>
      <AdvisorName>{consultant.name}</AdvisorName>
      <AdvisorTitle>{consultant.title}</AdvisorTitle>
    </BranchAdvisorCardContainer>
  );
};

const BranchAdvisorCardContainer = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: none;
  background: transparent;
  margin: 10px 33px 10px 33px;
`;

const AdvisorTitle = styled.p`
  font-family: Gill Sans;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  line-height: 24.3px;
  letter-spacing: -0.02em;
  text-align: center;
  color: rgba(88, 89, 91, 1);
`;

const AdvisorName = styled.h3`
  font-family: Poppins;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: -0.02em;
  text-align: center;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 0px;
`;

const PhotoContainer = styled.div`
  width: 233px;
  height: 233px;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export default BranchConsultantCardTeamPhoto;
