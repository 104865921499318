import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { inputTheme } from 'utilities/theme';
import { InputFields } from 'models/input';

const InputValidated: React.FC<{
  value: InputFields | undefined;
  placeholder?: string;
  type: string;
  name: string;
  id: string;
  ref?: any;
  onBlur?: Dispatch<SetStateAction<InputFields>>;
  onChange?: Dispatch<SetStateAction<InputFields>>;
  disabled?: boolean;
  theme?: string;
}> = ({
  value,
  placeholder,
  onChange,
  onBlur,
  type,
  name,
  id,
  ref,
  disabled = false,
  theme
}) => {
  return (
    <Field
      name={name}
      id={id}
      ref={ref}
      disabled={disabled}
      theme={theme}
      value={value?.value}
      onBlur={(e) => {
        if (onBlur) {
          if (!value) {
            onBlur({ value: e.target.value, error: true, touched: true });
          }
          if (value) {
            onBlur({ value: e.target.value, error: false, touched: true });
          }
        }
      }}
      onChange={(e) => {
        if (!disabled && onChange) {
          if (value) {
            onChange({
              ...value,
              value: e.target.value,
              error: false,
              touched: true
            });
          }
        }
      }}
      placeholder={placeholder}
      type={type}
    />
  );
};

interface DisabledProps {
  disabled: boolean;
  theme: string;
}
const Field = styled.input<DisabledProps>`
  background-color: ${(props) =>
    !props.disabled && props.theme === 'light'
      ? '#ffffff'
      : !props.disabled && props.theme === 'file'
      ? 'unset'
      : (!props.disabled && props.theme === 'form') ||
        (!props.disabled && props.theme === 'grid')
      ? '#F5F8F9'
      : props.disabled
      ? '#F3F3F3'
      : props.theme === 'contact'
      ? '#f5f8f9'
      : 'unset'};
  border-radius: unset;
  height: 50px;
  ${(props) => (props.theme ? inputTheme : inputTheme)};
  text-indent: 10px;
  font-family: Gill Sans;
  font-size: 16px;
  @media screen and (max-width: 768px) {
    margin: 8px 0px;
  }

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-family: Gill Sans Light;
    padding-left: 5px;
    font-size: 18px;
    color: #000000;
    opacity: 0.4;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    font-family: Gill Sans Light;
    padding-left: 5px;
    font-size: 18px;
    color: #000000;
    opacity: 0.4;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    font-family: Gill Sans Light;
    padding-left: 5px;
    font-size: 18px;
    color: #000000;
    opacity: 0.4;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    font-family: Gill Sans Light;
    padding-left: 5px;
    font-size: 18px;
    color: #000000;
    opacity: 0.4;
  }
  &:focus {
    outline: 2px dotted #000000;
  }
  &:hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'text')};
  }
`;

export default InputValidated;
