import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './assets/fonts/index.css';

if (document.getElementById('advisorfinder-appended-div') !== null) {
  ReactDOM.render(
    <App />,
    document.getElementById('advisorfinder-appended-div')
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
}
