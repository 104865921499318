import {
  Select,
  SelectProps,
  InputLabel,
  MenuItem,
  FormHelperText,
  Box
} from '@mui/material';
import { FieldProps } from 'formik';
import * as React from 'react';
import formikStyles from 'styles/Formik.module.css';

import { FieldWrapper } from './FormikTextField';

export interface FormikSelectFieldOption {
  value: number | string;
  label: string;
  disabled?: boolean;
}

interface FormikSelectFieldProps
  extends Omit<SelectProps, 'onChange' | 'variant'> {
  name: string;
  options: FormikSelectFieldOption[];
  onChange?: (value: unknown) => void;
}

export const FormikSelectField: React.FC<FormikSelectFieldProps> = ({
  ...props
}) => (
  <FieldWrapper name={props.name} fast={true}>
    {({ field, meta }: FieldProps<string>) => (
      <Box>
        <InputLabel className={formikStyles.label}>{props.label}</InputLabel>
        <Select
          sx={{
            width: '100%',
            backgroundColor: '#F5F8F9',
            '& fieldset': { border: 'none' },
            '& fieldset legend span': { display: 'none' }
          }}
          defaultValue="none"
          data-testid={props.name}
          displayEmpty
          error={Boolean(meta.touched && meta.error)}
          {...field}
          {...props}
          value={field.value === '' ? 'none' : field.value}
          onChange={(event) => {
            if (props.onChange) props.onChange(event.target.value);
            field.onChange(event);
          }}
        >
          <MenuItem key="none" value="none" disabled>
            Select one...
          </MenuItem>

          {props.options.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              disabled={option.disabled ?? false}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error={Boolean(meta.touched && meta.error)}>
          {meta.touched && meta.error}
        </FormHelperText>
      </Box>
    )}
  </FieldWrapper>
);
