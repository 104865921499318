import React, { useState } from 'react';
import queryString from 'query-string';

import { AdvisorFields, AdvisorUpdateParams, TeamFields } from 'models/advisor';
import collectSpecificAdvisorTeam from 'queries/collectSpecificAdvisorTeam';
import { advisorType } from 'utilities/CheckboxLists';
import * as yup from 'yup';

import Button from 'components/General/Button';
import image from 'assets/images/placeholder.svg';
import ClipLoader from 'react-spinners/ClipLoader';
import { Formik, Form } from 'formik';
import {
  FormikTextField,
  FormikTextArea,
  FormikRadioGroup,
  FormikCheckField
} from 'components/Form';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import {
  Container,
  Divider,
  FormSection,
  FormWrapper,
  LoadingContainer,
  Photo,
  PhotoContainer,
  PhotoDetails,
  SectionHeader,
  Subtext,
  Title,
  Tooltip
} from 'components/General/StyledComponents';
/* eslint-disable-next-line*/
const axios = require('axios');

const schema = yup.object().shape({
  name: yup.string(),
  title: yup.string(),
  email: yup.string().email(),
  linkedIn: yup.string().url(),
  facebook: yup.string().url(),
  bookAnAppointment: yup.string().url(),
  expertise: yup.array().of(yup.string()),
  description: yup.string(),
  comments: yup.string()
});

const EditTeamMemberScreen: React.FC = () => {
  const urlVariables = queryString.parse(window.location.search);
  const [advisorTeamSlug] = React.useState<string | string[] | null>(
    urlVariables.advisorTeamSlug
  );
  const [teamMemberSlug] = React.useState<string | string[] | null>(
    urlVariables.teamMemberSlug
  );
  const [loading, setLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [initialValues, setInitialValues] = useState<AdvisorUpdateParams>({
    advisor: {
      name: '',
      title: '',
      email: '',
      linkedIn: '',
      facebook: '',
      bookAnAppointment: '',
      photo: '',
      typeOfAdvisor: '',
      adviceYourWayAdvisor: false,
      id: '',
      description: '',
      locatorSlug: '',
      comments: '',
      expertise: []
    },
    team: {}
  });

  React.useEffect(() => {
    collectFields();
    // eslint-disable-next-line
  }, []);

  const collectFields = async () => {
    if (
      advisorTeamSlug &&
      typeof advisorTeamSlug === 'string' &&
      teamMemberSlug
    ) {
      const contentfulObject = await collectSpecificAdvisorTeam(
        advisorTeamSlug
      );
      const advisorTeam: TeamFields = contentfulObject[0];
      if (advisorTeam && teamMemberSlug) {
        const advisor: AdvisorFields | undefined =
          advisorTeam.advisorTeamInResultSet?.find(
            (advisor) => advisor.locatorSlug === teamMemberSlug
          );

        const contentfulAdvisorWithTeam = {
          team: {
            advisorTeamName: advisorTeam.name,
            advisorTeamAddress1: advisorTeam.address,
            advisorTeamAddress2: advisorTeam.address2,
            advisorTeamCity: advisorTeam.city,
            advisorTeamProvince: advisorTeam.province,
            advisorTeamPostalCode: advisorTeam.postalCode,
            advisorTeamMembers: getFriendlyTeamMembers(
              advisorTeam.advisorTeamInResultSet
            ),
            advisorTeamWebsite: advisorTeam.website,
            advisorTeamPhone: advisorTeam.phone
          },
          advisor: {
            name: advisor?.name ?? '',
            title: advisor?.title ?? '',
            email: advisor?.email ?? '',
            linkedIn: advisor?.linkedIn ?? '',
            facebook: advisor?.facebook ?? '',
            bookAnAppointment: advisor?.bookAnAppointment ?? '',
            photo: advisor?.photo ?? '',
            typeOfAdvisor: advisor?.typeOfAdvisor ?? '',
            adviceYourWayAdvisor: advisor?.adviceYourWayAdvisor ?? false,
            id: advisor?.id ?? '',
            description: advisor?.description ?? '',
            locatorSlug: advisor?.locatorSlug ?? '',
            comments: advisor?.comments ?? '',
            expertise: advisor?.expertise ?? []
          }
        };
        setInitialValues(contentfulAdvisorWithTeam);
      }
    }
  };

  const getFriendlyTeamMembers = (advisorTeamMembers?: AdvisorFields[]) => {
    let sentence = '';
    advisorTeamMembers?.forEach((advisor: AdvisorFields) => {
      sentence += `${advisor.name}, `;
    });
    return sentence.slice(0, -2);
  };

  const submitChanges = async (values: AdvisorUpdateParams) => {
    const { advisorTeamName } = values.team;
    if (!advisorTeamName) return;

    try {
      setLoading(true);
      try {
        const result = await axios({
          method: 'post',
          url: 'https://eszu55dnuf.execute-api.us-east-1.amazonaws.com/stage/write',
          //url: 'https://eszu55dnuf.execute-api.us-east-1.amazonaws.com/stage/writeStage',
          data: {
            modifiedTeamMemberObject: values.advisor,
            advisorTeamName,
            linkToView: `${process.env.REACT_APP_ROOT_URL}?advisorTeamSlug=${advisorTeamSlug}&teamMemberSlug=${teamMemberSlug}`
          }
        });
        if (result.status === 200) {
          enqueueSnackbar(
            'Your entry has been submitted successfully, and a record of your approval has been sent to an IPC Administrator. Thank you for your time!',
            {
              variant: 'success'
            }
          );
        }
      } catch (e) {
        enqueueSnackbar(
          'Something went wrong, please screenshot your webpage and contact your administrator',
          {
            variant: 'error'
          }
        );
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        onSubmit={submitChanges}
        validationSchema={schema}
        enableReinitialize
      >
        {({ values, setFieldValue, isValid, isSubmitting }) => (
          <Form>
            <Title>{`Welcome, ${initialValues.advisor.name}`}</Title>
            <Subtext>
              {`Congratulations for being included as part of IPC’s new Advisor Locator
        Tool. Please take a few minute to review to ensure all your information
        is accurate. You can make your edits directly on this form and hit
        Approve & Submit once complete. If no changes are required, please hit
        Approve & Submit to complete the process.`}
            </Subtext>
            <SectionHeader>Your information</SectionHeader>
            <FormWrapper>
              <FormSection>
                <PhotoContainer>
                  <Photo src={values.advisor.photo ?? image} alt="" />
                  <PhotoDetails>
                    <p>Your Image</p>
                    <Tooltip>
                      If you would like your photo modified, please submit a
                      note at the bottom of this form and your administrator
                      will contact you
                    </Tooltip>
                  </PhotoDetails>
                </PhotoContainer>
                <FormikTextField
                  label="Your name"
                  name="advisor.name"
                  placeholder="Name"
                />
                <FormikRadioGroup
                  name="advisor.typeOfAdvisor"
                  label="Type of Advisor"
                  options={advisorType.map((item) => ({
                    value: item.id,
                    label: item.label
                  }))}
                />
                <FormikCheckField
                  name="advisor.adviceYourWayAdvisor"
                  description="Are you an Advice Your Way advisor?"
                  label="Yes"
                />
                <FormikTextField
                  label="Your Title"
                  name="advisor.title"
                  placeholder="Title"
                />

                <FormikTextField
                  label="Your Email (used to direct contact form to email)"
                  name="advisor.email"
                  placeholder="Email"
                />
                <FormikTextField
                  label="Your LinkedIn Link"
                  name="advisor.linkedIn"
                  placeholder="LinkedIn"
                />
                <FormikTextField
                  label="Your Facebook Link"
                  name="advisor.facebook"
                  placeholder="Facebook"
                />
                <FormikTextField
                  label="Book an appointment link (optional) eg. if you have a
                      calend.ly link"
                  name="advisor.bookAnAppointment"
                  placeholder="Calend.ly link"
                />
              </FormSection>

              <Box style={{ paddingTop: '25px' }} />

              <FormikTextField
                label="Your Expertise"
                name="advisor.expertise"
                placeholder="Expertise 1, Expertise 2, Expertise 3, Expertise 4"
                onChange={(e) => {
                  const friendlyExpertise = e.target.value;
                  const untidy = friendlyExpertise.split(',');
                  const cleaned: string[] = [];
                  untidy.forEach((string) => {
                    cleaned.push(string.trim());
                  });
                  setFieldValue('advisor.expertise', cleaned);
                }}
              />

              <Box style={{ paddingTop: '25px' }} />

              <FormikTextArea
                label="Your Bio"
                name="advisor.description"
                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet, et mattis vivamus bibendum et libero mattis. Gravida lacus morbi aliquam dui. Duis nunc, massa varius justo."
              />

              <SectionHeader>Your office</SectionHeader>
              <FormSection>
                <FormikTextField
                  label="Advisor Office Name (internal use only)"
                  name="team.advisorTeamName"
                  disabled
                />

                <FormikTextField
                  label="Address 1"
                  name="team.advisorTeamAddress1"
                  disabled
                />

                <FormikTextField
                  label="Address 2"
                  name="team.advisorTeamAddress2"
                  disabled
                />

                <FormikTextField
                  label="City"
                  name="team.advisorTeamCity"
                  disabled
                />

                <FormikTextField
                  label="Province"
                  name="team.advisorTeamProvince"
                  disabled
                />

                <FormikTextField
                  label="Postal Code"
                  name="team.advisorTeamPostalCode"
                  disabled
                />

                <Box style={{ gridColumn: 'span 2' }}>
                  <FormikTextField
                    label="Team members"
                    name="team.advisorTeamMembers"
                    disabled
                  />
                </Box>
                <FormikTextField
                  label="Website"
                  name="team.advisorTeamWebsite"
                  disabled
                />

                <FormikTextField
                  label="Phone"
                  name="team.advisorTeamPhone"
                  disabled
                />
              </FormSection>
              <Divider />
              <FormikTextArea
                label="Comments or Change Requests"
                name="advisor.comments"
                placeholder="Your comments here..."
              />
              {loading ? (
                <LoadingContainer>
                  <ClipLoader color={'#FFFFFF'} loading={loading} size={25} />
                </LoadingContainer>
              ) : (
                <Button
                  disabled={!isValid || isSubmitting}
                  type="submit"
                  text={'Approve & Submit'}
                />
              )}
            </FormWrapper>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default EditTeamMemberScreen;
