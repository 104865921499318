// This package does not provide any typings
// @ts-ignore
import { gtag, install } from 'ga-gtag';

type Data = {
  [key: string]: string;
};

export const ReactGA4Install = (): void => {
  install('G-PM18RQCLKS', { send_page_view: false });
};

export const ReactGA4Event = (
  event: string,
  event_name: string,
  data: Data
): void => {
  try {
    gtag(event, event_name, data);
  } catch (e) {
    console.log(e);
  }
};
