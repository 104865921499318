import React from 'react';
import {
  OutlinedTextFieldProps,
  SxProps,
  Theme,
  Box,
  InputLabel
} from '@mui/material';
import { FastField } from 'formik';
import formikStyles from 'styles/Formik.module.css';

interface FormikTextAreaProps extends Omit<OutlinedTextFieldProps, 'variant'> {
  readonly sx?: SxProps<Theme> | undefined;
}

export const FormikTextArea: React.FC<FormikTextAreaProps> = ({ ...props }) => (
  <Box>
    <InputLabel className={formikStyles.label}>{props.label}</InputLabel>
    <FastField
      as="textarea"
      name={props.name}
      placeholder={props.placeholder}
      {...props}
      style={{
        ...props.style,
        width: '100%',
        backgroundColor: '#f5f8f9',
        border: 'none',
        padding: '10px'
      }}
      className={formikStyles.textArea}
    />
  </Box>
);
