import * as yup from 'yup';
import { BranchFormValues, TeamMemberFormValues } from 'models/branch';
import { provinces_codes } from 'utilities/CheckboxLists';

export const updateBranchSchema: yup.SchemaOf<BranchFormValues> = yup
  .object()
  .shape({
    branchName: yup.string(),
    branchLocation: yup.object().shape({
      address: yup.string(),
      address2: yup.string(),
      city: yup.string(),
      province: yup.string().oneOf(provinces_codes),
      postalCode: yup.string(),
      officePhone: yup.string()
    }),
    branchSlug: yup.string(),
    branchIntro: yup.string(),
    branchComments: yup.string(),
    branchPhotoTemplate: yup.string(),
    officeHours: yup.string(),
    id: yup.string()
  });

export const updateTeamMemberSchema: yup.SchemaOf<TeamMemberFormValues> = yup
  .object()
  .shape({
    name: yup.string(),
    title: yup.string(),
    description: yup.string(),
    typeOfAdvisor: yup.string(),
    email: yup.string().email(),
    linkedIn: yup.string(),
    facebook: yup.string(),
    bookAnAppointment: yup.string(),
    yourExpertise: yup.string(),
    hasPodcast: yup.string(),
    hasVideoLibrary: yup.string(),
    hasStreamArticles: yup.string(),
    comments: yup.string(),
    photo: yup.string(),
    locatorSlug: yup.string(),
    id: yup.string()
  });
