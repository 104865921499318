import { createClient } from 'contentful-management';
import { TeamMemberPostParams } from 'models/branch';
import * as Sentry from '@sentry/react';

const accessToken = `${process.env.REACT_APP_CONTENTFUL_WRITE_ACCESS_TOKEN}`;
const space = `${process.env.REACT_APP_CONTENTFUL_SPACE_ID}`;
const environment = `${process.env.REACT_APP_CONTENTFUL_ENVIRONMENT}`;

const postTeamMeberDetails = async (values: TeamMemberPostParams) => {
  const { id, teamMemberEdits } = values;

  const contentfulClient = createClient({
    accessToken
  });

  const contentful_space = await contentfulClient.getSpace(space);
  const contentful_environment = await contentful_space.getEnvironment(
    environment
  );

  try {
    const teamMemberObject = await contentful_environment.getEntry(id, {
      select: 'fields.teamMemberEdits'
    });

    if (!teamMemberObject) {
      throw new Error(`Team Member with id ${id} not found`);
    }

    teamMemberObject.fields.teamMemberEdits
      ? (teamMemberObject.fields.teamMemberEdits['en-US'] = teamMemberEdits)
      : (teamMemberObject.fields.teamMemberEdits = {
          ['en-US']: teamMemberEdits
        });

    const updatedTeamMember = await teamMemberObject.update();
    await updatedTeamMember.publish();
  } catch (err) {
    console.error(err);
    Sentry.captureException(err);
    throw err;
  }
};

export default postTeamMeberDetails;
