import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
  Typography
} from '@mui/material';
import { FastField, FieldProps } from 'formik';
import * as React from 'react';
import formikStyles from 'styles/Formik.module.css';

interface FormikCheckFieldProps extends Omit<FormControlLabelProps, 'control'> {
  name: string;
  checkboxProps?: CheckboxProps;
  description?: string;
}

const FormikCheckField: React.FC<FormikCheckFieldProps> = (props) => {
  const { description, ...rest } = props;
  return (
    <FastField name={rest.name}>
      {({ meta, form }: FieldProps<'yes' | 'no' | undefined>) => (
        <FormControl fullWidth>
          {description ? (
            <Typography className={formikStyles.label}>
              {description}
            </Typography>
          ) : null}
          <FormControlLabel
            {...rest}
            control={
              <Checkbox
                style={{ color: '#2A6337' }}
                data-testid={rest.name}
                checked={meta.value === 'yes'}
                color="primary"
                onChange={(event) => {
                  form.setFieldValue(
                    rest.name,
                    event.target.checked ? 'yes' : 'no'
                  );
                }}
              />
            }
          />

          {Boolean(meta.touched && meta.error) && (
            <FormHelperText error>{meta.error}</FormHelperText>
          )}
        </FormControl>
      )}
    </FastField>
  );
};

export default FormikCheckField;
