import {
  FormControlLabel,
  Radio,
  RadioGroup,
  FormHelperText,
  Grid,
  InputLabel,
  Box,
  Typography
} from '@mui/material';
import { FastField, FieldProps } from 'formik';
import * as React from 'react';
import formikStyles from 'styles/Formik.module.css';

interface FormikRadioGroupProps {
  name: string;
  label: string;
  options?: { value: string; label: string }[];
  disabled?: boolean;
}

const FormikRadioGroup: React.FC<FormikRadioGroupProps> = ({
  name,
  label,
  options,
  disabled
}) => (
  <FastField name={name}>
    {({ meta, form }: FieldProps<string[]>) => (
      <Box>
        <InputLabel className={formikStyles.label}>{label}</InputLabel>
        <RadioGroup
          name={name}
          value={meta.value}
          onChange={(event) => {
            form.setFieldValue(name, event.currentTarget.value);
          }}
        >
          <Grid container>
            {options?.map((option, i) => (
              <Grid item sm={6} xs={12} key={i}>
                <FormControlLabel
                  value={option.value}
                  control={
                    <Radio
                      sx={{
                        '&, &.Mui-checked': {
                          color: '#2A6337'
                        }
                      }}
                    />
                  }
                  label={
                    <Typography className={formikStyles.label}>
                      {option.label}
                    </Typography>
                  }
                  disabled={disabled}
                />
              </Grid>
            ))}
          </Grid>
        </RadioGroup>
        <FormHelperText
          error={Boolean(meta.touched && meta.error)}
          style={{ color: '#d32f2f' }}
        >
          {meta.touched ? meta.error : null}
        </FormHelperText>
      </Box>
    )}
  </FastField>
);

export default FormikRadioGroup;
