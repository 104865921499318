import { createClient } from 'contentful';
import { TeamFields } from 'models/advisor';
import {
  ContentulAdvisorCollectionResponse,
  ContentulTeamMemberCollectionResponse
} from 'models/contentfulResponse';

const collectSpecificAdvisorTeam = (urlSlug: string) => {
  const contentfulClient = createClient({
    accessToken: `${process.env.REACT_APP_CONTENTFUL_SECRET_TOKEN}`,
    space: `${process.env.REACT_APP_CONTENTFUL_SPACE_ID}`,
    environment: `${process.env.REACT_APP_CONTENTFUL_ENVIRONMENT}`
  });

  return contentfulClient
    .getEntries({
      'fields.slug': urlSlug,
      content_type: 'users',
      limit: 1,
      select: [
        'fields.advisorTeamName',
        'fields.location',
        'fields.showAdviceYourWay',
        'fields.phone',
        'fields.website',
        'fields.teamDescription',
        'fields.address',
        'fields.city',
        'fields.postalCode',
        'fields.province',
        'fields.teamMembers',
        'fields.facebookLink',
        'fields.linkedinLink',
        'fields.slug',
        'fields.officeHours',
        'fields.friendlyTeamName',
        'fields.officeLocation'
      ]
    })

    .then((entries) => {
      const allAdvisors: TeamFields[] = [];
      entries.items.forEach(
        //@ts-expect-error here assigning attributes to unknown type is giving me shit
        (rawContentfulAdvisorTeam: ContentulAdvisorCollectionResponse) => {
          allAdvisors.push({
            name: rawContentfulAdvisorTeam?.fields?.advisorTeamName,
            friendlyTeamName:
              rawContentfulAdvisorTeam?.fields?.friendlyTeamName,
            lat: Number(rawContentfulAdvisorTeam?.fields?.location?.lat),
            lon: Number(rawContentfulAdvisorTeam?.fields?.location?.lon),
            phone: rawContentfulAdvisorTeam?.fields?.phone,
            adviceYourWay: Boolean(
              rawContentfulAdvisorTeam?.fields?.showAdviceYourWay
            ),
            website: rawContentfulAdvisorTeam?.fields?.website,
            facebook: rawContentfulAdvisorTeam?.fields?.facebookLink,
            linkedin: rawContentfulAdvisorTeam?.fields?.linkedinLink,
            descriptionParagraph:
              rawContentfulAdvisorTeam?.fields?.teamDescription?.content[0]
                ?.content[0].value,
            address: rawContentfulAdvisorTeam?.fields?.address,
            city: rawContentfulAdvisorTeam?.fields?.city,
            postalCode: rawContentfulAdvisorTeam?.fields?.postalCode,
            province: rawContentfulAdvisorTeam?.fields?.province,
            slug: rawContentfulAdvisorTeam?.fields?.slug,
            advisorTeamInResultSet:
              rawContentfulAdvisorTeam?.fields?.teamMembers?.map(
                (teamMember: ContentulTeamMemberCollectionResponse) => {
                  return {
                    description:
                      teamMember?.fields?.description?.content[0]?.content[0]
                        ?.value,
                    photo: teamMember?.fields?.image?.fields?.file?.url,
                    name: teamMember?.fields?.name,
                    title: teamMember?.fields?.title,
                    locatorSlug: teamMember?.fields?.locatorSlug,
                    email: teamMember?.fields?.email,
                    adviceYourWayAdvisor:
                      teamMember?.fields?.adviceYourWayAdvisor,
                    bookAnAppointment: teamMember?.fields?.bookAnAppointment,
                    enableAdvisorLocator:
                      teamMember?.fields?.enableAdvisorLocator,
                    privateWealthAdvisor: Boolean(
                      teamMember?.fields?.privateWealthAdvisor
                    ),
                    facebook: teamMember?.fields?.facebook,
                    linkedIn: teamMember?.fields?.linkedIn,
                    typeOfAdvisor: teamMember?.fields?.typeOfAdvisor,
                    id: teamMember?.sys?.id,
                    comments: teamMember?.fields?.comments,
                    expertise: teamMember?.fields?.expertise
                  };
                }
              ),
            advisorTeam: rawContentfulAdvisorTeam?.fields?.teamMembers?.map(
              (teamMember: ContentulTeamMemberCollectionResponse) => {
                return {
                  description:
                    teamMember?.fields?.description?.content[0]?.content[0]
                      ?.value,
                  photo: teamMember?.fields?.image?.fields?.file?.url,
                  name: teamMember?.fields?.name,
                  title: teamMember?.fields?.title,
                  locatorSlug: teamMember?.fields?.locatorSlug,
                  email: teamMember?.fields?.email,
                  adviceYourWayAdvisor:
                    teamMember?.fields?.adviceYourWayAdvisor,
                  bookAnAppointment: teamMember?.fields?.bookAnAppointment,
                  enableAdvisorLocator:
                    teamMember?.fields?.enableAdvisorLocator,
                  privateWealthAdvisor: Boolean(
                    teamMember?.fields?.privateWealthAdvisor
                  ),
                  facebook: teamMember?.fields?.facebook,
                  linkedIn: teamMember?.fields?.linkedIn,
                  typeOfAdvisor: teamMember?.fields?.typeOfAdvisor,
                  id: teamMember?.sys?.id,
                  comments: teamMember?.fields?.comments,
                  expertise: teamMember?.fields?.expertise
                };
              }
            )
          });
        }
      );
      return allAdvisors;
    });
};

export default collectSpecificAdvisorTeam;
