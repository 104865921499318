import { FastField, Field, FieldProps } from 'formik';
import {
  Box,
  InputLabel,
  OutlinedTextFieldProps,
  SxProps,
  TextField,
  Theme,
  Typography
} from '@mui/material';
import * as React from 'react';
import formikStyles from 'styles/Formik.module.css';

export const FieldWrapper: React.FC<{
  children: React.ReactNode;
  name: string;
  fast: boolean;
}> = ({ children, name, fast }) => {
  if (fast) return <FastField name={name}>{children}</FastField>;

  return <Field name={name}>{children}</Field>;
};

interface FormikTextFieldProps extends Omit<OutlinedTextFieldProps, 'variant'> {
  name: string;
  fast?: boolean;
  sx?: SxProps<Theme> | undefined;
  regex?: RegExp;
  description?: string;
}

export const FormikTextField: React.FC<FormikTextFieldProps> = ({
  fast = true,
  ...props
}) => (
  <FieldWrapper name={props.name} fast={fast}>
    {({ field, meta }: FieldProps<string>) => (
      <Box>
        <InputLabel className={formikStyles.label}>{props.label}</InputLabel>
        <TextField
          data-testid={props.name}
          error={Boolean(meta.touched && meta.error)}
          helperText={meta.touched && meta.error}
          variant="outlined"
          placeholder={props.placeholder}
          fullWidth={props.fullWidth ?? true}
          {...field}
          {...props}
          label=""
          sx={{
            fontFamily: 'Gill Sans, sans-serif',
            borderRadius: '1px',
            '& .MuiInputBase-root': {
              backgroundColor: '#F5F8F9',
              borderRadius: '1px'
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#F5F8F9'
            }
          }}
          InputProps={{
            style: {
              border: 'none'
            }
          }}
          onChange={(e) => {
            if (props.onChange) {
              props.onChange(e);
            } else {
              field.onChange(e);
            }
          }}
        />
        {props.description && (
          <Typography
            sx={{ mx: 0, mt: 1, fontSize: 14, fontFamily: 'Gill Sans' }}
          >
            {props.description}
          </Typography>
        )}
      </Box>
    )}
  </FieldWrapper>
);
