import { createClient } from 'contentful';
import {
  ContentfulResponse,
  ContentulTeamMemberCollectionResponse
} from 'models/contentfulResponse';
import { AdvisorHybrid } from 'models/advisor';
import fieldMapper from './helpers/fieldMapper';

const searchfieldAdvisorCollect = async (advisorName: string) => {
  const contentfulClient = createClient({
    accessToken: `${process.env.REACT_APP_CONTENTFUL_SECRET_TOKEN}`,
    space: `${process.env.REACT_APP_CONTENTFUL_SPACE_ID}`,
    environment: `${process.env.REACT_APP_CONTENTFUL_ENVIRONMENT}`
  });
  const primaryOfficeArray: any[] = [];
  const teamMemberIDS = await contentfulClient
    .getEntries({
      'fields.name[match]': advisorName,
      content_type: 'teamMember',
      limit: 1000,
      select: ['fields.name', 'fields.primaryOffice']
    })

    .then((entries: any) => {
      const allAdvisors: string[] = [];
      entries.items.forEach((advisorName: ContentfulResponse) => {
        if (advisorName.fields.primaryOffice) {
          primaryOfficeArray.push(advisorName.fields.primaryOffice);
        }
        allAdvisors.push(advisorName.sys.id);
      });

      return allAdvisors;
    });

  const AdvisorCollection: AdvisorHybrid[] = [];

  const PromiseCollection: Promise<any>[] = [];
  teamMemberIDS.forEach((item) => {
    PromiseCollection.push(
      new Promise((resolve) => {
        resolve(
          contentfulClient.getEntries({
            content_type: 'users',
            'fields.teamMembers.sys.id': item,
            limit: 1,
            select: [
              'fields.advisorTeamName',
              'fields.location',
              'fields.showAdviceYourWay',
              'fields.phone',
              'fields.website',
              'fields.teamDescription',
              'fields.address',
              'fields.city',
              'fields.postalCode',
              'fields.province',
              'fields.teamMembers',
              'fields.facebookLink',
              'fields.linkedinLink',
              'fields.slug',
              'fields.officeHours',
              'fields.friendlyTeamName'
            ]
          })
        );
      })
    );
  });

  await Promise.all(PromiseCollection).then((entries: any[]) => {
    entries.forEach((applicableAdvisor: any) => {
      applicableAdvisor.items.forEach((rawContentfulAdvisorTeam: any) => {
        rawContentfulAdvisorTeam?.fields?.teamMembers.forEach(
          (teamMember: any) => {
            if (
              teamMemberIDS.includes(teamMember.sys.id) &&
              teamMember.fields &&
              teamMember?.fields?.enableAdvisorLocator
            ) {
              rawContentfulAdvisorTeam?.fields?.teamMembers?.forEach(
                (flaggedAdvisor: ContentulTeamMemberCollectionResponse) => {
                  if (teamMemberIDS.includes(flaggedAdvisor.sys.id)) {
                    const mappedFields = fieldMapper(rawContentfulAdvisorTeam);
                    let officeOverride = null;
                    if (flaggedAdvisor?.fields?.primaryOffice) {
                      officeOverride = primaryOfficeArray.find(
                        (office) =>
                          office.sys.id ===
                          flaggedAdvisor?.fields?.primaryOffice?.sys?.id
                      );
                    }
                    AdvisorCollection.push({
                      teamFields: {
                        name: mappedFields.name,
                        friendlyTeamName: mappedFields.friendlyTeamName,
                        lat: mappedFields.lat,
                        lon: mappedFields.lon,
                        phone: mappedFields.phone,
                        adviceYourWay: mappedFields.adviceYourWay,
                        website: mappedFields.website,
                        facebook: mappedFields.facebook,
                        linkedin: mappedFields.linkedin,
                        descriptionParagraph: mappedFields.descriptionParagraph,
                        address: mappedFields.address,
                        city: mappedFields.city,
                        postalCode: mappedFields.postalCode,
                        province: mappedFields.province,
                        slug: mappedFields.slug,
                        officeHours: mappedFields.officeHours,
                        advisorTeamInResultSet:
                          mappedFields.advisorTeamInResultSet,
                        advisorTeam: mappedFields.advisorTeam
                      },
                      advisorFields: {
                        description:
                          flaggedAdvisor?.fields?.description?.content[0]
                            ?.content[0]?.value,
                        photo: flaggedAdvisor?.fields?.image?.fields?.file?.url,
                        name: flaggedAdvisor?.fields?.name,
                        title: flaggedAdvisor?.fields?.title,
                        locatorSlug: flaggedAdvisor?.fields?.locatorSlug,
                        email: flaggedAdvisor?.fields?.email,
                        adviceYourWayAdvisor:
                          flaggedAdvisor?.fields?.adviceYourWayAdvisor,
                        bookAnAppointment:
                          flaggedAdvisor?.fields?.bookAnAppointment,
                        enableAdvisorLocator:
                          flaggedAdvisor?.fields?.enableAdvisorLocator,
                        privateWealthAdvisor: Boolean(
                          flaggedAdvisor?.fields?.privateWealthAdvisor
                        ),
                        facebook: flaggedAdvisor?.fields?.facebook,
                        linkedIn: flaggedAdvisor?.fields?.linkedIn,
                        typeOfAdvisor: flaggedAdvisor?.fields?.typeOfAdvisor,
                        id: flaggedAdvisor?.sys?.id,
                        comments: flaggedAdvisor?.fields?.comments,
                        expertise: flaggedAdvisor?.fields?.expertise,
                        primaryOffice: officeOverride
                          ? fieldMapper(officeOverride)
                          : undefined
                      }
                    });
                  }
                }
              );
            }
          }
        );
      });
    });
  });

  return AdvisorCollection;
};

export default searchfieldAdvisorCollect;
