import { Box } from '@mui/material';
import { Container } from 'components/General/StyledComponents';
import { Branch } from 'models/branch';
import { enqueueSnackbar } from 'notistack';
import collectSpecificBranch from 'queries/collectBranchDetails';

import queryString from 'query-string';
import React, { useState } from 'react';
import {
  getBranchDetailsInitialValues,
  getTeamMemberInitialValues
} from 'utilities/branch';
import BranchLandingScreenTeamPhoto from 'components/General/branch/BranchLandingScreenTeamPhoto';
import BranchLandingScreenNoTeamPhoto from 'components/General/branch/BranchLandingScreenNoTeamPhoto';
import getCollection, {
  BranchPageSettingsData,
  BranchTemplateOptions
} from 'queries/collectBranchPageSettingsDuda';

const BranchLandingScreen: React.FC = () => {
  const urlVariables = queryString.parse(window.location.search);
  const [loading, setLoading] = useState(true);
  const [errorBranch, setErrorBranch] = useState('');

  const [initialValues, setInitialValues] = useState<Branch>({
    branchDetails: {},
    teamMembers: [],
    consultants: []
  });

  const [dudaData, setDudaData] = useState<BranchPageSettingsData | null>(null);

  React.useEffect(() => {
    collectBranch();
    getData();
  }, []);

  const collectBranch = async () => {
    setLoading(true);
    try {
      const branch = await collectSpecificBranch(
        urlVariables.branchLandingSlug as string
      );

      if (branch) {
        const branchDetails = getBranchDetailsInitialValues(
          branch.branchDetails
        );
        const teamMembers = branch.teamMembers.map((teamMember) =>
          getTeamMemberInitialValues(teamMember)
        );
        const consultants = branch.consultants.map((consultant) =>
          getTeamMemberInitialValues(consultant)
        );
        setInitialValues({ teamMembers, branchDetails, consultants });
      }
    } catch (err) {
      console.log(err);
      const error = err as Error;
      enqueueSnackbar(error.message, { variant: 'error' });
      setErrorBranch(error.message);
    } finally {
      setLoading(false);
    }
  };

  const expertise = React.useMemo(() => {
    const expertiseLocal: string[] = [];
    initialValues.teamMembers.forEach((teamMember) => {
      teamMember?.yourExpertise?.split(', ').forEach((expertise) => {
        if (!expertiseLocal.includes(expertise)) {
          expertiseLocal.push(expertise);
        }
      });
    });
    return expertiseLocal;
  }, [initialValues.teamMembers]);

  const iirocAdvisors = React.useMemo(() => {
    return initialValues.teamMembers.filter(
      (teamMember) => teamMember.typeOfAdvisor === 'IIROC'
    );
  }, [initialValues.teamMembers]);

  const mfdaAdvisors = React.useMemo(() => {
    return initialValues.teamMembers.filter(
      (teamMember) => teamMember.typeOfAdvisor === 'MFDA'
    );
  }, [initialValues.teamMembers]);

  const error = (
    <Container>
      <Box sx={{ my: 3 }}>Something went wrong: {errorBranch}</Box>
    </Container>
  );

  const loadingScreen = <Box py="100px" />;
  const getData = async () => {
    const res = await getCollection(
      'cf7174f4',
      'Branch',
      urlVariables.branchLandingSlug as string
    );
    setDudaData(res);
  };
  console.log(dudaData);

  if (loading) return loadingScreen;
  if (errorBranch) return error;

  return (
    <>
      {dudaData ? (
        <>
          {dudaData?.['Branch Template'][0] ===
          BranchTemplateOptions.TeamPhoto ? (
            <BranchLandingScreenTeamPhoto
              branch={initialValues}
              expertise={expertise}
              iirocAdvisors={iirocAdvisors}
              mfdaAdvisors={mfdaAdvisors}
              dudaData={dudaData}
              loading={loading}
              errorBranch={errorBranch}
            />
          ) : (
            <BranchLandingScreenNoTeamPhoto
              branch={initialValues}
              expertise={expertise}
              iirocAdvisors={iirocAdvisors}
              mfdaAdvisors={mfdaAdvisors}
              dudaData={dudaData}
              loading={loading}
              errorBranch={errorBranch}
            />
          )}
        </>
      ) : null}
    </>
  );
};

export default BranchLandingScreen;
